.range-dropdowns
  display: flex
  align-items: center
  .range-separator
    margin: 0 5px
    &:before
      content: '-'

.location-bubble
  display: inline-block
  .removable-bubble
    margin-right: 3px
    i
      padding-left: 3px

#platform-cta.tooltip
  margin-top: 16px
