.listings-map {
    .listings-map-marker {
      box-shadow: 0px 6px 20px rgba(51, 51, 51, 0.16);

      &::before, &::after {
        content: "";
        display: block;
        position: absolute;
        height: 0;
        width: 0;
        border: solid transparent;
      }

      &::before {
        top: 100%;
        border-width: 7px;
      }

      &::after {
        top: 95%;
        border-width: 7px;
      }

      // Arrows on the bottom
      // The ::after selector sets the inside color
      &.active::after {
        border-top-color: #caf0d6;
      }

      // The ::before selector sets the border color
      &.active::before {
        border-top-color: #63d689;
      }

      &.pending::after {
        border-top-color: #fae4c3;
      }

      &.pending::before {
        border-top-color: #fab957;
      }

      &.contingent::after {
        border-top-color: #fad5c3;
      }

      &.contingent::before {
        border-top-color: #fa8e58;
      }

      &.sold::after {
        border-top-color: #c8d9fa;
      }

      &.sold::before {
        border-top-color: #578efa;
      }

      &.offmarket::after {
        border-top-color: #f5f5f5;
      }

      &.offmarket::before {
        border-top-color: #999999;
      }
    }
  }
