.assigned-plans-column, #assigned-plans-tooltip-wrap{
  .fa-bolt{
    &.empty{ color: #B3B3B3; }
    &.running{ color: #59C4C4; }
    &.completed{ color: #336E7B; }
    &.paused{ color: #F2714D; }
  }
}
#assigned-plans-tooltip-wrap{
  text-align: left;
  white-space: nowrap;

  .tooltip-inner{
    max-width: 300px!important;
  }
  i.fa-bolt{
    padding-right: 5px;
  }
}

.assigned-plans{
  &.white-panel{
    .header-row{
      .row{
        display: flex;
        align-items: center;
      }
      .header-row-title{
        font-size: 18px;
        padding: 10px 0;
        font-weight: 300;
      }
      .column-header{
        font-size: 12px;
        color: #B3B3B3;
      }
      .action-link{
        color: #59C4C4;
        font-size: 12px;
        margin-left: auto;
        &:hover{
          text-decoration: none;
        }
      }
      .checkbox-inline{
        user-select: none;
        display: flex;
        align-items: center;
        padding-left: 10px;

        input[type=checkbox]{
          margin-top: 0px;
        }
      }
    }
    .assigned-plan-row{
      padding: 10px 0;
      font-size: 14px;
      color: #666;

      .state{
        font-weight: bold;
      }
      .state.completed{
        color: #336E7B;
        &:before{
          content: 'Completed';
        }
      }

      .state.deleted{
        color: #666;
        padding-right: 5px;
        &:before{
          content: 'Deleted';
        }
      }

      .state.started{
        color: #59C4C4;
        &:before{
          content: 'Running';
        }
      }

      .state.paused{
        color: #F2714D;
        &:before{
          content: 'Paused';
        }
      }

      .applied-plan-actions{
        flex-grow: 1;
        text-align: right;
      }

      .inline-item{
        padding-right: 10px;
        display: inline-block;
      }

      .inline-item:last-child{
        padding-right: 0;
      }
    }
  }
}

.assigned-plan-modal{
  .modal-header{
    padding-bottom: 0px;
    span.state{
      padding-left: 10px;
      font-weight: bold;
    }
    .state.completed{
      color: #336E7B;
      &:before{
        content: 'Completed';
      }
    }
    .state.deleted{
      color: #666;
      padding-right: 5px;
      &:before{
        content: 'Deleted';
      }
    }
    .state.started{
      color: #59C4C4;
      &:before{
        content: 'Running';
      }
    }
    .state.paused{
      color: #F2714D;
      &:before{
        content: 'Paused';
      }
    }
  }
  .assigned-plan-action-buttons{
    color: #666666;
    margin-left: auto;
    margin-right: 20px;

    .action-button{
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .modal-body{
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.assigned-plan-detail-grid{
  display: flex;
  width: 100%;
  min-height: 500px;
  max-height: 700px;
  max-width: 100%;

  .left-column{
    padding-top: 5px;
    border-right: 1px solid #E0E0E0;
    min-width: 275px;
    width: 275px;
    overflow-y: scroll;
  }
  .right-column{
    flex-grow: 1;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    overflow: hidden;
    background-color: #F8F7F6;
  }
  .grid-items{
    display: flex;
    flex-flow: column;
    width: 100%;

    .day-label{
      color: #666666;
      font-size: 14px;
      font-weight: bold;
      padding: 10px 0;
      padding-left: 15px;
    }
    .grid-item{
      display: flex;
      align-items: center;
      font-size: 12px;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 15px;
      padding-right: 10px;
      border-bottom: 1px solid #E0E0E0;

      .action-icon-circle{
        margin: 8px 15px 8px 8px;
        height: 30px;
        width: 30px;
        min-width: 30px;
        background-color: white;

        i{
          font-size: 14px;
        }
        &.default{
          border: 1px solid #E6E6E6;
        }
        &.deleted{
          background-color: #E6E6E6
        }
        &.overdue{
          border: 1px solid #f2714d;
        }
      }
      .title{
        font-size: 14px;
        color: #666666;
      }
      .one-line{
        white-space: nowrap;
        overflow: hidden;
        max-width: 200px;
        text-overflow: ellipsis;
      }
      &.active{
        background-color: #F8F7F6;
      }
    }
  }
  .active-action{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    .action-name{
      font-size: 14px;
      padding-bottom: 5px;
    }
    .action-fail-message{
      text-align: right;
      color: #F2714D !important;
      font-size: 14px;
    }

    .row-border:after {
      content: "";
      display: block;
      border-bottom: 1px solid #E0E0E0;
      padding-top: 11px;
      margin: 0 10px;
    }
    .header-wrap{
      padding: 0px;
    }
    .sub-header-wrap{
      padding-top: 11px;
      max-height: 500px;
    }
    .call-to-actions-row{
      margin-top: auto;
      padding-top: 11px;
      .call-to-actions-left, .call-to-actions-right{
        display: flex;
      }

      .call-to-actions-right{
        margin-left: auto;
      }

      &:before{
        content: "";
        display: block;
        border-top: 1px solid #E0E0E0;
        padding-bottom: 11px;
        margin: 0 10px;
      }
      &:after{
        border-bottom: 0px;
      }
      .call-to-actions{
        margin: 0 10px;
        display: flex;
      }
    }
    .content-wrap{
      font-size: 14px;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 10px;
      margin-top: auto;

      h4{
        color: #4A4A4A;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
