.any{
  color: #303030;
}

.action-plan-type-header{
  font-size: 18px;
}

.action-plan-name-header{
  margin-top: 0px;
  padding-bottom: 20px;
  font-size: 18px;

  .edit-icon{
    margin-left: 10px;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
  }

}
.radio-inline, .checkbox-inline{
  font-size: 14px;
  color: #666666;
  display: inline-block!important;
  margin-bottom: 0!important;
}

.editable-inline{
  margin-bottom: 20px;

  input.action-name-input{
    width: 350px;
  }
}

.auto-plan-timeline{

  .auto-plan-timeline-day{
    padding-bottom: 20px;

    .auto-plan-timeline-day-content{
      border-left: 1px solid #e6e6e6;
      padding-top: 40px;
      padding-bottom: 10px;
    }

    .auto-plan-timeline-action{
      padding-bottom: 30px;

      .auto-plan-timeline-action-item{
        margin-right: 15px;

        .description{
          font-size: 12px;
        }
        .from-label{
          color: #969696;
        }
        .due-time-label{
          color: #B3B3B3;
        }
        .fa.fa-pencil, .fa.fa-trash{
          cursor: pointer;
          color: #666;
        }
        &:last-child{
          margin-left: auto;
          margin-right: 15px;
        }
      }
    }

  }

  .auto-plan-timeline-left-column{
    flex: 1 175px;
    max-width: 175px;
    padding-left: 20px;
    padding-right: 40px;

    .time-label{
      padding-top: 10px;
    }
  }

  .auto-plan-timeline-right-column{
    flex: 1;
  }

  .day-label{
    font-size: 20px;
    font-weight: bold;
    color: #666;
    margin-bottom: 20px;
  }

  .time-label{
    padding-left: 10px;
    color: #B3B3B3;
  }
}
