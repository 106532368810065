.trigger-table{

  .table-header-row{

    .action-link{
      a{
        color: #59C4C4;
        font-size: 12px;
        text-decoration: none;
      }
    }
  }
}
