#cma-map {
  .gm-bundled-control {
    .gmnoprint {
      div {
        > div {
          border-radius: 50%;
        }
      }
    }
  }
}
