@media screen and (max-width: 1320px) {
    .personDetailLeftFlexbox {
        min-width: 281px;
        max-width: 375px;
        width: 25%;
        flex-grow: 1;
    }

    .personDetailCenterFlexbox {
        min-width: 650px;
        width: 50%;
        flex-grow: 2;
    }

    .personDetailRightFlexbox {
        min-width: 281px;
        max-width: 375px;
        width: 25%;
        flex-grow: 1;
    }
}

@media screen and (min-width: 1321px) and (max-width: 1536px) {
    .personDetailLeftFlexbox {
        min-width: 300px;
        max-width: 375px;
        width: 23%;
        flex-grow: 1;
    }

    .personDetailCenterFlexbox {
        min-width: 650px;
        width: 54%;
        flex-grow: 2;
    }

    .personDetailRightFlexbox {
        min-width: 300px;
        max-width: 375px;
        width: 23%;
        flex-grow: 1;
    }
}

@media screen and (min-width: 1537px) {
    .personDetailLeftFlexbox {
        min-width: 300px;
        max-width: 450px;
        width: 23%;
        flex-grow: 1;
    }

    .personDetailCenterFlexbox {
        min-width: 704px;
        width: 54%;
        flex-grow: 2;
    }

    .personDetailRightFlexbox {
        min-width: 300px;
        max-width: 450px;
        width: 23%;
        flex-grow: 1;
    }
}

.brivityBtnGrayOutline {
    box-sizing: border-box;
    height: 36px;
    border: 2px solid rgba(102, 102, 102, 0.7);
    border-radius: 17.99px;
    color: rgba(102, 102, 102, 0.7);
    font-size: 12px;
    font-family: "Open Sans";
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
    padding: 9px 15px;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none !important;
}

.brivityBtnGrayOutline:hover {
    border: 2.12px solid #59C4C4;
    color: #59C4C4;
}

.brivityBtnGrayOutline.active {
    color: #ffffff;
    border: 2.12px solid #59C4C4;
    background-color: #59C4C4;
}

.brivitySocialIcon {
    height: 37.6px;
    width: 37.6px;
    border-radius: 18.8px;
    color:rgba(89, 196, 196,1);
    background-color: rgba(89, 196, 196,0.2);
    text-align: center;
    cursor: pointer;
}

.brivitySocialIconDisabled {
    height: 37.6px;
    width: 37.6px;
    border-radius: 18.8px;
    color: rgba(151, 151, 151, 1);
    background-color: rgba(151, 151, 151, 0.2);
    text-align: center;
    cursor: default;
}

.brivitySocialIcon > i, .brivitySocialIconDisabled i {
    text-align: center;
    vertical-align: middle;
    line-height: 37.6px;
}

.brivitySocialIcon:hover {
    color:rgba(89, 196, 196,1);
}

.brivitySocialIconDisabled:hover {
    color: rgba(151, 151, 151, 1);
}

.brivityPillBlue {
    background-color: rgba(89, 196, 196, 0.2);
    color: #59C4C4;
    font-size: 10px;
    line-height: 14px;
    display: inline-block;
    padding: 3px 12px;
    border-radius: 10px;
    text-transform: uppercase;
}

.brivityPillRed {
    background-color: rgba(188, 69, 29, 0.2);
    color: #BC451D;
    font-size: 10px;
    line-height: 14px;
    display: inline-block;
    padding: 3px 12px;
    border-radius: 10px;
    text-transform: uppercase;
}

.brivityPersonInfoCard {
    padding: 20px 14px 20px 20px;
    border-radius: 4px 4px 0px 0px;
    background-color: white;
    border-bottom: 1px solid #E6E6E6;
}

.brivityPrevNextWrapper {
    display: flex;
}

.brivityPrevNextBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: rgba(184, 184, 184, 0.1);
    margin-top: 20px;
    margin-left: 10px;
    text-decoration: none !important;
    cursor: pointer;
}

.brivityPrevNextBtn > i {
    font-size: 14px;
}

.brivityPrevNextBtn.enabled > i {
    color: #FFFFFF;
}

.brivityPrevNextBtn.enabled {
    background-color: #59c4c4;
}

.brivityPrevNextBtn.enabled:hover {
    background-color: rgba(89, 196, 196,0.3);
}

.brivityPrevNextBtn.enabled:hover > i {
    color: #59c4c4;
}

.brivityPrevNextBtn.disabled {
    background-color: rgba(184, 184, 184, 0.1);
}

.brivityPrevNextBtn.disabled > i {
    font-size: 14px;
    color: rgba(184, 184, 184, 0.3);
}

.brivityPersonAvatarWrapper {
    text-align: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #E6E6E6;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    clear: both;
}

.brivityPersonAvatarWrapper img {
    border-radius: 50%;
}

.brivityPersonAvatarInitials {
    line-height: 28px;
    font-weight: 400;
    color: #666;
    text-transform: uppercase;
    font-size: 30px;
}

.brivityPersonDetailsWrapper {
    text-align: center;
}

.brivityPersonDetailsName {
    font-weight: bold;
}

.brivityPersonDetailsSource {
    font-weight: bold;
    color: #666666;
}

.brivityPersonDetailsSourceLabel {
    color: #949393;
    font-weight: 600;
}

.brivityPersonDetailsRegistered {
    font-size: 12px;
    font-weight: bold;
    color: #666666;
}

.brivityPersonDetailsLastInteraction {
    font-weight: bold;
    color: #666666;
}

.brivityPersonDetailsStatusIntent {
    font-size: 10px;
    font-weight: bold;
    color: #666666;
}

.brivityPersonDetailsStatusIntentLabel {
    color: #949393;
    font-weight: 600;
}

.brivityPersonDetailsType {
    font-weight: bold;
    color: #666666;
}

.brivityPersonDetailsShowInLeads {
    font-weight: bold;
    color: #949393;
    font-size: 10px;
}

.brivityPersonContactCard {
    padding: 20px 14px 20px 20px;
    background-color: white;
    border-bottom: 1px solid #E6E6E6;
}

.brivityPersonContactHeader {
    color: #666666;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 23px;
    margin-bottom: 20px;
}

.brivityPersonContactHeader .toggleCollapse {
    cursor: pointer;
    flex: 1
}

.brivityPersonContactHeader .toggleCollapse > i {
    font-size: 12px;
    margin-right: 15px;
    color: #59C4C4;
    -moz-transition: transform 0.1s linear;
    -webkit-transition: transform 0.1s linear;
    transition: transform 0.1s linear;
}

.collapsedHeader {
    -ms-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.brivityPersonContactItem {
    display: flex;
}

.brivityPersonContactLabel {
    color: #666666;
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 14px;
    text-transform: uppercase;
}

.brivityPersonContactContents {
    display: flex;
    overflow: hidden;
}

.brivityPersonContactSocial {
    display: flex;
    justify-content: space-between;
    max-width: 225px;
}

.brivityPersonContactIcon {
    margin-right: 5px;
}

.brivityPersonContactIcon > i {
    margin-right: 5px;
}

.brivityPersonContactValue {
    font-size: 14px;
    overflow: hidden;
}

.brivityPersonDetailsCard {
    padding: 20px 14px 20px 20px;
    background-color: white;
    border-bottom: 1px solid #E6E6E6;
}

.cardRoundedTop {
    border-radius: 4px 4px 0px 0px;
}

.brivityPersonDetailsHeader {
    color: #666666;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 23px;
    margin-bottom: 20px;
}

.brivityPersonDetailsHeader > i {
    font-size: 12px;
    margin-right: 15px;
    color: #59C4C4
}

.brivityPersonDetailsTitle {
    font-weight: 600;
    margin: 0 0 11px 0;
}

.brivityPersonDetailsLabel {
    color: #666666;
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 14px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.brivityPersonDetailsText {
    word-break: break-all;
    color: #666666;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
}

.brivityPersonDetailsTag {
    border-radius: 10.2px;
    padding: 4px 6px 4px 6px;
    color:#336E7B;
    background-color: rgba(89, 196, 196,0.2);
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
    text-transform: uppercase;
    margin: 0px 5px 0px 5px;
}

.brivityPersonRelationshipsCard {
    padding: 20px 14px 20px 20px;
    background-color: white;
    border-bottom: 1px solid #E6E6E6;
}

.brivityPersonRelationshipsHeader {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.brivityPersonRelationshipsHeaderText {
    color: #666666;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
    letter-spacing: 0;
}

.brivityPersonRelationshipsHeaderText > i {
    font-size: 12px;
    margin-right: 15px;
    color: #59C4C4;
}

.brivityPersonRelationshipItem {
    display: flex;
    margin-bottom: 20px;
}

.brivityPersonRelationshipName {
    color: #666666 !important;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    text-decoration: none !important;
}

.brivityPersonRelationshipType {
    color: #666666;
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0;
    text-transform: uppercase;
}

.card {
    transition:max-height 0.3s ease-out;
    height:auto;
}

.collapsed {
    max-height: 65px;
    overflow:hidden;
}

.brivityCardBoxShadow {
    box-shadow: 0 2px 4px 0 #E0E0E0;
}

.circleOutlineIcon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    vertical-align: middle;
    padding: 30px;
}

.personDetailLabel {
    color: #666666;
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 13px;
    text-transform: uppercase;
}

.addDetailLink {
    color: #59C4C4;
    text-decoration: none;
}

.addDetailLink:hover {
    color: #59C4C4;
    cursor: pointer;
    text-decoration: none;
}

.addressFadeOverflow {
    overflow: hidden;
    white-space: nowrap;
}

.addressFadeOverflow:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20%;
    height: 1.2em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

.contactInfoFadeOut {
    white-space: nowrap;
    font-size: 14px;
    overflow: hidden;
}

.contactInfoFadeOut:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

.contactInfoFadeOutHover:after {
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(250, 250, 250, 1) 50%);
}

.personDetailEmptyStateText {
    color: #666666;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
}

.inlineDetail {
    margin-bottom: 11px;
}

.inlineDetail:last-of-type {
    margin-bottom: 0;
}

.inlineLabelValue {
    margin-left: 4px;
    font-size: 14px;
}

.materialDesignBox {
    border-radius: 7px;
    background-color: #FFFFFF;
    padding: 10px;
    margin: 10px;
    box-shadow: 0 2px 8px 0 rgba(213,213,213,0.5);
}

.selectEmailDropdown {
    position: relative;
    display: inline-block;
    padding-right: 10px;
}

.selectEmailDropdownContent {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
    border-radius: 4px;
    padding: 12px 16px 2px 16px;
    z-index: 2;
}

.selectEmailDropdown:hover .selectEmailDropdownContent {
    display: block;
}

.emailStatusIcon {
    height: 24px;
    width: 24px;
}

.emailStatusCaret {
    padding-left: 5px;
}

.cursorPointer {
    cursor: pointer;
}

.faDisabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.note_content {
    max-height: 80px;
    white-space: pre-wrap
}
.note_content_on_view_all {
    max-height: 115px;
    height: 115px;
    white-space:pre-wrap;
}
.view_all_notes_close_button {
    width: 24px !important;
    height: 24px !important;
    margin-top: 1px;
}
.create_new_note_container {
    padding-top: 10px;
    padding-left: 20px;
}
.car_note_sidebar {
    width: 265px;
    height: 227px;
    padding-left: 20px;
    padding-right: 20px;
}
