.projectIcon {
  margin-right: 10px;
}

.projectIcon a {
  display: flex;
  width: 40px;
  height: 40px;
  background-color: rgba(89, 196, 196,0.2);
  border-radius: 4px;
  text-decoration: none;
}

.projectIcon i {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 40px;
}

.type {
  font-size: 12px;
}
