input.zoomer {
  -webkit-appearance: none;
  background-color: #666;
  height: 3px;
  width: 96px;
  border-radius: 100px;
  -webkit-tap-highlight-color: transparent;
  padding: 0;
}

.zoomer::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 17px;
  height: 17px;
  background: #666;
  border-radius: 50%;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
