.interactTabsList {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: #ffffff;
    overflow: hidden;
    border-radius: 4px 4px 0px 0px;
}

.interactTab {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    display: block;
    background: rgb(238,238,238);
    background: linear-gradient(0deg, rgba(238,238,238,1) 0%, rgba(249,249,249,0.5) 7%, rgba(255,255,255,0.5) 100%);
    padding: 13px 20px 13px 20px;
    color: #666666;
    font-family: "Open Sans";
    font-size: 17px;
    letter-spacing: 0;
    margin-top: 5px;
    z-index: 1;
    cursor: pointer;
}

.interactTabsList::after{
    content: "";
    background: rgb(238,238,238);
    background: linear-gradient(0deg, rgba(238,238,238,1) 0%, rgba(249,249,249,0.5) 7%, rgba(255,255,255,0.5) 100%);
    display: flex;
    flex-grow: 100;
}

.activeTab {
    margin-top: 0px;
    border-top: 5px solid var(--primary-color-alternative);
    font-weight: bolder;
    background: none !important;
    box-shadow: 0 0 8px 0px rgba(0,0,0,0.20);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 2;
}

.interactTextInput {
    border-radius: 2px;
    background-color: rgba(236,237,239,0.6);
    color: #666666;
    border: none;
    padding: 10px;
    width: 100%;
}

.interactToLabel {
    margin-top: 8px;
    margin-right: 2px;
    font-size: 12px;
    color: #656565;
    font-weight: normal;
    line-height: 17px;
    font-family: "Open Sans";
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
}

.interactSelect {
    display: block;
    width: 100%;
    height: 36px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #999999;
    background-color: #F5F7F7;;
    background-image: none;
    border-radius: 2px;
    border: 1px solid #E0E0E0;
}

.smsConversationWrapper {
    max-height: 330px;
    overflow: scroll;
}

.smsLoadingState {
    color: #B3B3B3;
}

.mediaBubble {
    max-width: 35%;
    height: auto;
    border-radius: 10px;
}

.chatBubble {
    height: auto;
    width: auto;
    border-radius: 10px;
    padding: 10px;
    margin: 8px;
    font-size: 14px;
}

.chatBubbleFromClient > .chatBubble {
    background-color: var(--neutral-gray-5);
    border: 1px solid var(--neutral-gray-10);
    margin-right: 48px;
}

.chatBubbleFromAgent > .chatBubble {
    background-color: var(--brivity-blue-10);
    border: 1px solid var(--brivity-blue-20);
    margin-left: 48px;
}

.chatBubbleFromClient {
    float: left;
    margin-left: 0px;
}

.chatBubbleFromAgent {
    float: right;
    margin-right: 0px;
}

.chatAvatar {
    width: 40px !important;
    max-height: 40px !important;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    margin: 10px auto 4px auto;
}

.chatAvatarInitials {
    vertical-align: top;
    margin-right: 1rem;
    display: inline-block;
    line-height: 38px;
    font-size: 18px;
    font-weight: 600;
    background-color: #E6E6E6;
    color: #666666;
    text-transform: uppercase;
    text-align: center;
    width: 40px !important;
    max-height: 40px !important;
    height: 3em;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    margin: 8px auto 4px auto;
}

.disabledTab {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    display: block;
    background: rgb(238,238,238);
    background: linear-gradient(0deg, rgba(238,238,238,1) 0%, rgba(249,249,249,0.5) 7%, rgba(255,255,255,0.5) 100%);
    padding: 13px 20px 13px 20px;
    color: #d4d4d4;
    font-family: "Open Sans";
    letter-spacing: 0;
    margin-top: 5px;
    z-index: 1;
    cursor: pointer;
}

.starOnPDP {
    margin-left: 96%;
}

.note_modal_footer {
    margin: 35px 0 13px 0
}

.note_delte_button {
    margin-top: 6px
}
