.listingGrid {
  margin-left: -7px;
  margin-right: -7px;
}

.listingGrid .listingContainer {
  margin-bottom: 20px;
}

.listingGrid .listingContainer .cardBody {
  border-radius: 7px;
  box-shadow: 0 2px 8px 0 rgba(213, 213, 213, 0.5);
  height: 200px;
  overflow-x: hidden;
  position: relative;
}

.listingGrid .listingContainer .cardBody .favorited {
  color: #F2714D;
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}
.listingGrid .listingContainer .listingPhoto {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 132px;
}

.listingGrid .listingContainer .listingPhoto::before {
  content: '';
  display: block;
  position: absolute;
  background-color: #000;
  opacity: 0.4;
  width: 100%;
  height: 200px;
}

.listingGrid .listingContainer .listingPhoto .photoText {
  position: absolute;
  bottom: 12px;
  left: 10px;
  color: white;
  text-decoration: none;
}

.listingGrid .listingContainer .listingPhoto .photoText .price {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 6px;
}

.listingGrid .listingContainer .cardBody .histories {
  height: 65px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.listingGrid .listingContainer .cardBody .histories::-webkit-scrollbar {
  width: 0;
}

.listingGrid .listingContainer .cardBody .histories .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 5px;
}

.listingGrid .listingContainer .cardBody .histories .historyLabel {
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 13px;
  color: #666666;
  display: inline-block;
  float: left;
  margin-left: 10px;
}

.listingGrid .listingContainer .cardBody .histories .viewBubble {
  display: inline-block;
  float: right;
  font-size: 10px;
  font-weight: bold;
  line-height: 13px;
  border-radius: 12px;
  background-color: #F4F5F5;
  padding: 4px 10px;
  margin-right: 10px;
}


.address {
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
}

.bedsBaths {
  font-size: 12px;
  font-weight: bold;
  line-height: 13px;
  margin-top: 5px;
}

.dateAndTime {
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
}

.date {
  font-weight: 600;
}

.filters {
  margin-top: 15px;
  margin-bottom: 40px;
}

.filterText {
  bottom: 2px;
  position: relative;
  font-size: 12px;
}

.viewOrFavorite {
  width: 200px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.propertiesTooltip {
  background-color: #666666 !important;
  padding: 5px 12px !important;
}
