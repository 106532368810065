#transactions-react__wrapper {
  .transactions__tooltip {
    background-color: #fff;
    color: #666666;
    font-size: 14px;
    padding: 5px 10px;
    border: 1px solid #e6e6e6;
    border-radius: 0;
    opacity: 1;
    box-shadow: 0px 2px 6px #b3b3b3;
  }

  #transactions-table {
    .transactions__header th:nth-child(1),
    .transactions__transaction > td:nth-child(1),
    .transactions__footer > td:nth-child(1) {
      z-index: 2;
      position: sticky;
      left: 0;
    }

    .transactions__header th:nth-child(2),
    .transactions__transaction > td:nth-child(2) {
      background-color: white;
      position: sticky;
      z-index: 2;
      left: 80px;
      padding-left: 12px;
    }

    .transactions__header th:nth-child(1),
    .transactions__transaction > td:nth-child(1) {
      background-color: white;
    }

    .transactions__transaction:not(:nth-child(1)) > td {
      border-top: 1px solid #e6e6e6;
    }
  }

  div.actionable:hover {
    cursor: pointer;
  }

  span.actionable:hover {
    cursor: pointer;
  }

  .actionable svg {
    visibility: hidden;
  }

  .actionable:hover svg {
    visibility: initial;
  }
}
