.mr-d {
  margin: 0 auto !important;
  width: 1224px !important;
  max-width: 100% !important;
  max-height: 96vh !important;
  height: 800px;
  overflow: hidden;
  top: 50%;
  transform: translate(0, -50%) !important;

  transition:
    width 0.3s,
    height 0.3s;
  &.mr-d-loading {
    height: 54px !important;
    width: 54px !important;
    top: 45%;
  }
  &.mr-d-memail {
    height: auto !important;
    width: 440px !important;
    top: 30%;
  }
  .modal-content {
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .fa-chevron-right {
    font-size: 14px;
  }

  .preview {
    flex: 1;
    height: 70vh;
    max-height: 100%;
    width: 708px;
    background-color: #f5f5f5;
  }
  .map {
    background-color: #f5f7f7;
  }
  .aside {
    padding: 12px 2rem 0;
    height: 100%;
    width: 430px;
    overflow-y: auto;
  }
  .fa-mobile {
    // override bad icon sizing
    font-size: 28px;
  }

  .Select-control {
    background-color: #f5f7f7;
    height: 36px;
    line-height: 38px;
    border-radius: 4px;
    color: #666666;
    // .Select-input { height: 36px;}
    .Select-multi-value-wrapper {
      align-items: center;
      display: inline-flex;
    }
    .Select-placeholder {
      color: #666666;
      line-height: 38px;
    }
    .Select-value-label {
      line-height: 38px;
      color: #666666 !important;
    }
  }
  .Select {
    &.range-error {
      .Select-control {
        border-color: #fa9996;
      }
      &.is-focused:not(.is-open) {
        .Select-control {
          border-color: #fa9996;
        }
      }
    }
  }
}
