.cc-input-field {
  .alert-cc-email {
    padding-top: 10px;

    input {
      width: 95%;
      margin-right: 14px;
      float: left;
    }

    .remove-cc {
      height: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .remove-cc:hover {
      text-decoration: none;
    }
  }

  .cc-multi-select-dropdown {
    position: absolute;
    left: 0.8em;
    top: 4em;
    max-width: 100px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid 0 0 0 0.15;
    box-shadow: 0 6px 12px;
  }

  .cc-search-results-container {
    z-index: 3000;
    max-width: 2000px;
    border-radius: 0px;
    border-top: 1px;
  }

  .cc-search-result {
    line-height: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 7px;
    padding-right: 7px;
    cursor: pointer;

    i {
      margin-right: 10px;
    }
  }
}
