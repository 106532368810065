.details h5 {
  font-weight: 600;
  margin-top: 0;
  text-transform: none;
}

.details p {
  font-size: 12px;
}

.details p strong {
  font-size: 10px;
}

.checkMark {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  text-align: center;
  line-height: 190%;
  border: 2px solid #666666;
  font-size: 18px;
}

.content {
  display: inline-block;
  margin-left: 20px;
  width: 480px;
}

.appointmentRow {
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 30px;
  margin-bottom: 40px;
}

.appointmentRow h5 {
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 0;
  text-transform: none;
}

.appointmentRow p, .appointmentRow span {
  font-size: 12px;
  line-height: 21px;
}

.appointmentRow p {
  margin-bottom: 3px;
}

.appointmentRow:first-of-type {
  border-top: 1px solid #E6E6E6;
  margin-top: 10px;
  padding-top: 40px;
}
