.trigger {
  border: 2px solid #2196f3;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  width: 100%;
}

.content {
  flex-shrink: 0;
  background: #fff;
  border-radius: 8px;
  padding: 0px 10px;
  position: absolute;
  margin-left: -0.7%;
  margin-top: 25px;
  box-shadow: 0px 6px 20px 2px rgba(51, 51, 51, 0.16);
  z-index: 1;
}

.statusIntentButton {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.selectedStatusIntent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.selectedStatusIntentText:after {
  font-family: FontAwesome;
  content: "\F078";
  font-size: 8px;
  margin-left: 6px;
  position: relative;
  bottom: 1px;
}

.selectedFrequencyText {
  color: #666666;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 500;
}

.selectedStatusIntent:focus {
  outline: none !important;
}

.statusIntentOption {
  display: table !important;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 700;
  margin: 0px 12px;
  text-align: center !important;
  height: 20px;
  text-transform: capitalize;
  width: 120px;
  height: 24px;
  border: 2px solid;
}

.statusFrequencyOption {
  width: 112px;
  height: 30px;
  margin: 0px -10px 0px -10px;
  display: table;
}

.statusFrequencyOption:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.selectedFrequencyTextalign {
  display: table-cell;
  vertical-align: middle;
}

.item {
  padding: 10px;
  transition: background 0.1s linear;
}
.item:hover {
  background: #2196f3;
  color: #fff;
  cursor: pointer;
}

.dropdown {
  box-shadow: 0 4px 10px rgba(124, 77, 255, 0.2);
  margin: 50px auto;
  outline: none;
  position: relative;
  transition: box-shadow 0.1s linear;
  width: 300px;
}
.dropdown.active {
  box-shadow: 0 10px 30px rgba(124, 77, 255, 0.2);
}
.dropdown:hover > .trigger,
.dropdown.active > .trigger {
  background: #2196f3;
  color: #fff;
}
.dropdown.active > .trigger {
  background: #2196f3;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  color: #fff;
}
