.formLabel {
  font-size: 10px;
  line-height: 13px;
  color: #666666;
  font-weight: 700;
  letter-spacing: 0.2px;
}

/* New, improved style for the future */
.formLabel2 {
  font-size: 14px;
  line-height: 19px;
  color: #666666;
  font-weight: 700;
  margin-bottom: 8px;
}

.formControl {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #666666;
  background-color: #ECEFEF;
  background-image: none;
  border: none;
  border-radius: 2px;
}

.formControlSm {
  display: block;
  width: 100%;
  padding: 3px 7px;
  font-size: 11px;
  height: 22px;
  color: #666666;
  background-color: #ECEFEF;
  background-image: none;
  border: none;
  border-radius: 2px;
}

.formControlSmDate input {
  display: block;
  width: 100%;
  padding: 3px 7px;
  font-size: 11px;
  height: 22px;
  color: #666666;
  background-color: #ECEFEF;
  background-image: none;
  border: none;
  border-radius: 2px;
}

.rowNarrow {
  margin-top: 6px;
}

input.formControl {
  height: 29px;
}

.fauxRadioButton input {
  display: none;
}

.fauxRadioButton span {
  margin-left: 8px
}

.fauxCheckBox {
  color: #949393;
}

.checkBoxContainer {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.fauxCheckBox label {
  font-size: 14px;
  color: #666;
  position: absolute;
  left: 22px;
  cursor: pointer;
}

.required label::after {
  margin-left: 0.4em;
  color: #F2714D;
  content: "*";
}

label.required::after {
  margin-left: 0.4em;
  color: #F2714D;
  content: "*";
}
