.milestones-index{
  .custom-date-new-icon{
    margin-right: 10px;
  }

  .new-custom-date-action{
    margin-top: -4px
  }

  .brivity-panel-header{
    padding-bottom: 25px;
  }

  .milestone-table-header-row > div{
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 5px;
  }

  .milestone-table-text-align-left{
    text-align: left;
  }

  .milestone-table-text-align-center{
    text-align: center;
  }

  .milestone-table-text-align-right{
    text-align: right;
  }

  .milestone-table-row{
    border-bottom: 1px solid #E6E6E6;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .milestone-form-radio-text{
    margin-top: 2px;
  }

  .brand-primary{
    color: #3B3B3B;
  }

  .gray{
    color: #B3B3B3;
  }

  .disabled-cursor{
    cursor: not-allowed;
  }

  .milestones-modal-buttons{
    margin-bottom: 0px;
  }
}
