#searchResults {
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #CCC;
  width: 100%;
  box-shadow: 0 1px 5px 1px rgba(0,0,0,0.15), 0 10px 16px 0 rgba(0,0,0,0.2);
  max-height: 25em;
  overflow: auto;
}

#searchResults .resultLabel {
  font-weight: 700;
  line-height: 20px;
  padding: 7px;
}

#searchResults .resultChildren .resultItem {
  cursor: pointer;
  line-height: 20px;
  padding: 7px 7px 7px 25px;
}

#searchResults .resultChildren .resultItem.highlight {
  font-weight: bold;
  background: #e6e6e6;
  color: #666;
}

.removableBubble {
  display: inline-block;
  padding: 0.3em 0.6em;
  border: 1px solid #E6E6E6;
  border-radius: 1em;
  font-size: 12.6px;
  font-weight: normal;
  line-height: 17px;
  color: #666666;
  background-color: #E6E6E6;
  margin-bottom: 5px;
  margin-right: 3px;
}

.remove {
  cursor: pointer;
  font-size: 15px;
  position: relative;
  top: 0px;
  margin-left: 6px;
}

#searchBubbles {
  margin-top: 10px;
}

#searchByMap .mapContainer {
  height: 300px;
  width: 100%;
}

.searchResults {
  text-transform: uppercase;
  margin-top: 10px;
  margin-right: 20px;
  font-weight: 600;
}

.searchResults a {
  text-decoration: none;
}

#alertContainer {
  height: 475px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 10px;
}

.viewAllAlert::-webkit-scrollbar {
  width: 0;
}

.hideHorizontalScroll {
  background-color: white;
  color: white;
  height: 16px;
  position: relative;
  z-index: 1;
}

.advancedOptions {
  margin-bottom: 15px;
}

.optionType {
  margin-bottom: 0px;
}

.optionType label {
  text-transform: uppercase;
}

.moreOrLess {
  font-size: 12px;
  cursor: pointer;
}

.upOrDownIcon {
  font-size: 12px;
  margin-left: 6px;
}

.optionLabel {
  font-size: 14px;
  color: #666;
  position: absolute;
  left: 22px;
  cursor: pointer;
}

.optionContainer {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 8px;
}

.option {
  color: #949393;
}

.viewAllCard {
  box-shadow: 0px 2px 15px 3px rgba(0, 0, 0, 0.07);
  height: 247px;
  width: 285px;
}

#addNewAlert {
  cursor: pointer;
}

.alert {
  padding: 20px;
}

.lgBell {
  font-size: 64px;
  margin-top: 66px;
  margin-bottom: 30px;
}

.viewAllAlert {
  height: 122px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.propertyTypeBubble {
  border: 1px solid #E6E6E6;
  border-radius: 1em;
  color: #808080;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  margin-right: 0.4rem;
  margin-bottom: 5px;
  padding: 4px 8px;
}

.propertyTypeBubble.selected {
  background-color: #59C4C4;
  color: white;
}

#ccResult {
  width: 100%;
  margin-top: 3px;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #CCC;
  box-shadow: 0 1px 5px 1px rgba(0,0,0,0.15);
  line-height: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 7px;
  padding-right: 7px;
  cursor: pointer;
}

.ccEmail {
  float: left;
  margin-bottom: 10px;
  width: 100%;
}

.ccEmail input {
  cursor: not-allowed !important;
}

.ccEmail .input {
  width: 100%;
}

.ccEmail i {
  cursor: pointer;
  color: #666;
  margin-top: 5px;
}

.card {
  padding-bottom: 0px;
}

.card .content {
  height: 170px;
  overflow-y: scroll;
  float: left;
}

.searchName {
  width: 190px;
  position: relative;
}

.searchName i {
  font-size: 14px;
  margin-right: 8px;
}

.searchName span {
  font-weight: 600;
}

.overflow {
  position: relative;
  width: 82%;
}

@media screen and (min-width: 1731px) and (max-width: 2010px) {
  .overflow {
    width: 80%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1730px) {
  .overflow {
    width: 78%;
  }
}

@media screen and (min-width: 1460px) and (max-width: 1599px) {
  .overflow {
    width: 76%;
  }
}

@media screen and (min-width: 1321px) and (max-width: 1459px) {
  .overflow {
    width: 72%;
  }
}

@media screen and (max-width: 1320px) {
  .overflow {
    width: 70%;
  }
}
