.search-map {
  .search-map-marker {
    box-shadow: 0px 6px 20px rgba(51, 51, 51, 0.16);

    &::before, &::after {
      content: "";
      display: block;
      position: absolute;
      height: 0;
      width: 0;
      border: solid transparent;
    }

    // Arrows on the bottom
    &::before {
      top: 100%;
      border-width: 7px;
      border-top-color: #c2c2c2;
    }

    &::after {
      top: 95%;
      border-width: 7px;
      border-top-color: white;
    }

    &.viewed::after {
      top: 100%;
      border-width: 7px;
      border-top-color: #e0e0e0;
    }

    &.selected::after {
      top: 100%;
      border-width: 7px;
      border-top-color: #59c4c4;
    }
  }
}
