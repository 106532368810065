.auto-plan-table
  .table-header-row
    .action-link
      a
        color: #59C4C4
        font-size: 12px
        text-decoration: none

  .table-row
    .action-plan-icon
      color: #969696

    .radio-inline
      display: inline-block!important

    .auto-plan-action-icons
      i
        color: #3b3b3b

.any
  color: #303030
.new
  color: #BC451D
.unqual
  color: #59C4C4
.watch, .p
  color: #336E7B
.nurt, .a
  color: #FBC02D
.hot, .pr
  color: #F2714D
.past
  color: #B3B3B3
.inactiv
  color: #808080
.arch
  color: #666666
.trash
  color: #3B3B3B

.toggle-on
  position: relative
  height: 20px
  width: 40px
  border-radius: 150px
  background-color: #59C4C4

.toggle-on-circle
  position: absolute
  top: 1px
  left: 21px
  height: 18px
  width: 18px
  border-radius: 9px
  background-color: #FFFFFF

.toggle-off
  position: relative
  height: 20px
  width: 40px
  border-radius: 150px
  background-color: #E6E6E6

.toggle-off-circle
  position: absolute
  top: 1px
  left: 1px
  height: 18px
  width: 18px
  border-radius: 9px
  background-color: #FFFFFF

.auto-plans-form
  padding: 0 15px

  .form-description
    color: #808080
    margin-bottom: 15px

  label.radio-inline:not(:last-child)
    padding-right: 20px

.shared-email-bubble
  color: white
  background-color: #59C4C4
  display: inline-block
  margin-right: 10px
  padding: 0 8px 0 6px
  border-radius: 4px
  .fa-times
    margin-right: 5px

#owner-emails-field
  height: auto
  min-height: 37px
