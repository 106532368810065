#lead-dna {
  .form-group {
    min-height: unset;
    margin: 12px 0 0;
  }

  .leaddna-filter-dropdown {
    display: inline-block;
    width: 100px;
  }

  .inline-label {
    display: inline-block !important;
    position: relative;
    bottom: 11px;
    margin-right: 7px;
    margin-left: 10px;
  }

  .chart-container {
    position: relative;
    height:90px;
    width:100%;
  }

  #secondary-select-wrapper {
    display: inline-block;
    background-color: lightgrey;
    height: 30px;
    border: 1px solid #ccc;
    position: relative;

    #secondary-close-box {
      display: inline-block;
      text-align: center;
      width: 27px;
      height: 30px;
      position: relative;
      bottom: 15px;

      i.fa.fa-times-circle {
        position: relative;
        top: 5px;
      }
    }

    .secondary-filter-dropdown {
      position: relative;
      bottom: 1px;
      left: 1px;
    }
  }

  .leaddna-legend {
    list-style: none;
    padding: 0;
    margin: 0 0 0 7px;
    display: inline-block;

    li {
      padding-left: 1em; 
      text-indent: -.7em;
      display: inline;
      position: relative;
      margin-right: 15px;
      color: #b3b3b3;
    }

    li::before {
      content: "•  ";
      font-size: 39px;
      position: absolute;
      top: -18px;
      left: 10px;
    }

    span.color-text {
      font-weight: 700;
    }

    li.light-blue {
      span.light-blue, &::before {
        color: rgb(89,196,196);
      }
    }

    li.yellow {
      span.yellow, &::before {
        color: rgb(244,179,80);
      }
    }

    li.dark-red {
        span.dark-red, &::before {
        color: rgb(188,52,14);
      }
    }

    li.dark-blue {
      span.dark-blue, &::before {
        color: rgb(51,110,123);
      }
    }

    li.coral-red {
        span.coral-red, &::before {
        color: rgb(242,113,77);
      }
    }

    li.gray {
        span.gray, &::before {
        color: rgb(179,179,179);
      }
    }
  }
}
