.arrows {
  display: inline-block;
  position: relative;
  top: -0.9rem
}

.arrows i {
  display: inline-block;
  font-size: 10px;
  margin-left: 5px;
  position: absolute;
  left: 0;
}

.columnTitle {
  color: #666;
  display: inline-block;
  padding-right: 1rem;
  cursor: pointer;
  font-size: 9px;
  font-weight: bold;
  line-height: 17px;
}

.divider {
  border-bottom: 1px solid #e6e6e6;
}

.documentRow {
  align-items: center;
  display: flex;
  height: 51px;
}

.documentRow:hover {
  background-color: rgba(0,0,0,0.02);
}

.documentRow span {
  font-weight: 600;
}

.documentRow span a, .documentRow span a:hover {
  color: #666;
}

.documentRow a {
  cursor: pointer;
}

.documentRow .download {
  color: #59C4C4;
}

.userImage  {
  display: inline-block;
  margin-right: 10px;
}

.documentOverflow {
  height: 36px;
  width: 80%;
  position: relative;
}

.uploaderOverflow {
  position: relative;
  width: 70%;
}

@media screen and (min-width: 1463px) and (max-width: 1540px) {
  .documentOverflow {
    width: 190px;
  }
}

@media screen and (min-width: 1398px) and (max-width: 1462px) {
  .documentOverflow {
    width: 175px;
  }
}

@media screen and (min-width: 1292px) and (max-width: 1397px) {
  .documentOverflow {
    width: 160px;
  }
}

@media screen and (max-width: 1291px) {
  .documentOverflow {
    width: 150px;
  }
}

@media screen and (max-width: 1291px) {
  .uploaderOverflow {
    width: 135px;
  }
}
