.tooltip-matches > .tooltip-inner {
  border-radius: 4px;
  background-color: #666666;
  box-shadow: none !important;
  padding: 6px 12px !important;
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 512px !important;
}

.tooltip-matches.tooltip.top .tooltip-arrow {
  border-top: 5px solid #666666 !important;
}
