@mixin option {
  font-family: "Open Sans", Arial, sans-serif;
  padding: 2px 5px 3px 5px;
  color: #333;
}

.options-cell-container {
  position: relative;
}

.selectable-options-container {
  margin-top: 2px;

  box-shadow: 0 0px 7px 0px #e3e3e3;

  &.options-single-full-width {
    width: 100%;
  }
}

.selectable-input-container {
  &.options-single-full-width {
    width: 100%;
  }

  width: 200px;
  border-radius: 5px 5px 0 0;
  padding: 5px;
  box-shadow: 0 0px 7px 0px #e3e3e3;
}

.selectable-options {
  &.options-single-full-width {
    width: 100%;
  }

  width: 200px;
  min-height: auto;
  max-height: 160px;
  overflow-y: auto;
  overflow-x: clip;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 5px 7px 0px #e3e3e3;
  border-radius: 0 0 5px 5px;
}

.selected-option {
  color: #333;
}

.selectable-search-input {
}

.option {
  @include option;

  &:hover {
    background-color: #eee;
  }
}

.no-option {
  @include option;
}

.options-cell-icon {
  margin-left: 5px;
}

.options-cell-hint {
  font-family: "Open Sans", Arial, sans-serif;
  padding: 2px 5px 3px 3px;
  color: #333;
}

.options-single-400 {
  width: 400px;
}

.options-single-icon-full-width {
  margin-left: auto;
}
