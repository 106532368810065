.home-app-column {
  .fa-mobile {
    &.empty {
      color: #b3b3b3;
    }

    &.sent {
      color: #59c4c4;
    }
  }

  .lower-row {
    margin-top: 4px;
  }

  .menu {
    background-color: white;
    border-radius: 6px;
    max-height: 350px;
    overflow-y: scroll;
    padding: 10px 0;
    position: absolute;
    width: 100%;
    z-index: 10;
    margin-top: 0;
    margin-left: 25px;
    top: 10px;
    left: 43px;
  }

  .menu .label {
    margin-bottom: 8px;
    margin-left: 10px;
  }

  .menu .label span {
    display: block;
    margin-bottom: 8px;
  }

  .menu input {
    width: 80%;
  }

  .menu::-webkit-scrollbar {
    width: 0;
  }

  .menuitem {
    padding-left: 16px;
    padding-right: 16px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 36px;
  }

  .menuitem div {
    display: inline-block;
  }

  .menuitem:hover {
    cursor: pointer;
    background-color: #EEF9F9;
    color: #59c4c4;
    i {
      color: #59C4C4;
    }
  }

  .menuitem:active {
    cursor: pointer;
    background-color: #59C4C4;
    color: #FFFFFF;
    i {
      color: #FFFFFF;
    }
  }
  
  .overlayPosition {
    position: relative;
    width: 154px;
    left: 0 !important;
  }
}
