.tooltip
{
  margin-left: 6px;
  vertical-align: baseline;
  z-index: 100
}

.groupRadio
{
  cursor: pointer;
}

.groupRadio span {
  margin-left: 5px
}

.selectionCount {
  background-color: #E2E3E5;
}

.getDemo
{
  text-align: center;
  margin-top: 10px;
}

.modalImage
{
  margin-bottom: 20px;
  width: 100%;
}

.modalBody
{
  margin: 10px;
}

.modalText
{
  margin: 10px 20px 0 20px;
}

.modalInnerText
{
  margin-top: 10px;
}

