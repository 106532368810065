.triggerArea {
  cursor: pointer;
}

.triggerArea .agentInfo, .triggerArea .selectNew {
  height: 50px;
}

.triggerArea .selectNew {
  justify-content: flex-end;
}

.triggerArea .agentInfo .image span {
  margin-bottom: 0px !important;
}

.agentInfo .image {
  margin-right: 15px;
}

.triggerArea .agentInfo span, .triggerArea .agentInfo strong {
  color: #666;
  display: block;
}

.triggerArea .agentInfo span {
  font-size: 14px;
  font-weight: 600;
}

.triggerArea .agentInfo strong {
  font-size: 10px;
}

.moreOrLess {
text-decoration: none !important;
cursor: pointer;
}

.moreOrLess:hover {
  color: #59C4C4;
}
