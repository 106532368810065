$color-draft: #e27757;
$color-pipeline: #ffa826;
$color-comingsoon: #75c2c3;
$color-active: #ebb562;
$color-pending: #e27757;
$color-sold: #416d79;
$color-expired: #3b3b3b;
$color-withdrawn: #3b3b3b;
$color-canceled: #3b3b3b;
$color-archived: #b3b3b3;

.selectedPill {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 11px;
  padding: 4px 10px;
  border-radius: 30px;

  > svg {
    margin-left: 5px;
  }
}

.selectableStatuses {
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 5px 7px 0px #e3e3e3;
  border-radius: 5px;
}

.option {
  padding: 5px 10px;
}

.pill {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 11px;
  padding: 4px 10px;
  border-radius: 30px;
  cursor: pointer;
}

.pill-draft {
  background-color: $color-draft;
  &:hover {
    background-color: darken($color-draft, 5%);
  }
}

.pill-comingsoon {
  background-color: $color-comingsoon;
  &:hover {
    background-color: darken($color-comingsoon, 8%);
  }
}

.pill-active {
  background-color: $color-active;
  &:hover {
    background-color: darken($color-active, 8%);
  }
}

.pill-pipeline {
  background-color: $color-pipeline;
  &:hover {
    background-color: darken($color-pipeline, 8%);
  }
}

.pill-pending {
  background-color: $color-pending;
  &:hover {
    background-color: darken($color-pending, 5%);
  }
}

.pill-sold {
  background-color: $color-sold;
  &:hover {
    background-color: darken($color-sold, 10%);
  }
}

.pill-expired {
  background-color: $color-expired;
  &:hover {
    background-color: lighten($color-expired, 10%);
  }
}

.pill-withdrawn {
  background-color: $color-withdrawn;
  &:hover {
    background-color: lighten($color-withdrawn, 10%);
  }
}

.pill-canceled {
  background-color: $color-canceled;
  &:hover {
    background-color: lighten($color-canceled, 5%);
  }
}

.pill-archived {
  background-color: $color-archived;
  &:hover {
    background-color: lighten($color-archived, 5%);
  }
}
