.itemz-exit {
  opacity: 1;
  transform: translateY(0);
}

.itemz-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition:
    opacity 300ms,
    transform 300ms;
}

.date-groupz-exit {
  opacity: 1;
  transform: translateY(0);
}
.date-groupz-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition:
    opacity 300ms,
    transform 300ms;
}
