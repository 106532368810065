.pond-or-agent-filter {
  // This is used to force override styles of hardcoded images/avatars
  .force-img-sm .circled.user-image,
  .force-img-sm .circled.default-avatar {
    font-size: 10px;
    line-height: 26px;
    width: 26px !important;
    height: 26px !important;
    margin: 0;
  }

  input {
    padding: 0 20px 0 4px;
    &::placeholder {
      color: #999;
    }
  }
}
