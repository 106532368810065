// Reset code for global container to set full height and width
.modal[bsPrefix="lp-delete"]
    z-index:1075
.lp-delete-bck
  z-index:1075
.fp-reset-wrap
  position: absolute
  top: 100px
  bottom: 0
  right: 10px
  width: auto
  background: #F8F7F6
  overflow: hidden
  // background: gray
  @media (max-width: 768px) 
    left: 10px
  // global site page wrapper
  > .col-sm-12 
    margin: 0 0 0 -10px
    height: 100%
  > .scrollable-row
    z-index: 45
  // The React component wrapper
  > div [id*='react-component']
    z-index: 1
    position: relative
    height: 100%
    width: 100%

// Modal Animation
@keyframes PondModalIn
  0%
    transform: scale(0.7) translateX(-60%)
  100%
    transform: scale(1) translateX(-50%)
// new and edit modal
.lp-tr
  position: relative
  opacity: 0
  left: -40px
  transition: all ease .5s
  &.on
    left: 0
    opacity: 1
.pond-modal
  animation-name: PondModalIn
  animation-duration: .5s
  max-height: calc(100vh - 140px)
.lp-input
    &::placeholder
      color: #aaa
// Inner View wrapper
.lead-ponds-view 
  font-size: 16px
  padding-left: 8px
  overflow-y: auto
  height: 100%
  width: 100%
  // reset for all components
  * 
    padding: 0 
    margin: 0 
    box-sizing: border-box
  .alert
    padding: 15px
    margin-top: 30px 0
    position: fixed
    top: 0

  // data table
  .lp-table-section
    // min-width: 1200px
  .data
    min-width: 1200px
    background: #fff
  thead
    th
      background: #fff
      padding: 8px 8px
      position: sticky
      top: 10px
      z-index: 45
      box-shadow: inset 0 -1px 0 #ebebeb
    tr
      color: rgba(0,0,0,.8)
  tbody
    tr
      cursor: pointer
      border-bottom: 1px solid #ebebeb
.enforce-avatar
  .user-image
    display: inline-block
    height: 36px
    width: 36px !important
    border-radius: 100%
