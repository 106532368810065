.type {
  font-size: 12px;
}

.name {
  font-weight: 600;
  display: inline-block;
}

.name span,
.name a {
  color: #666;
}

.name a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.assignedPlanApplied {
  color: #59c4c4;
}

.taskHeader {
  text-transform: uppercase;
  border-bottom: solid 1px #e6e6e6;
}

.taskHeader h6 {
  font-size: 10px;
  color: #666;
  font-weight: 600;
}
