.range-slider {
  .thumb {
    pointer-events: none;
    -webkit-tap-highlight-color: transparent;

    @mixin thumb-style {
      -webkit-appearance: none;
      -webkit-tap-highlight-color: transparent;
      background-color: #fff;
      border: none;
      border-radius: 50%;
      box-shadow: 0 0 1px 1px #59c4c4;
      cursor: pointer;
      height: 18px;
      width: 18px;
      margin-top: 4px;
      pointer-events: all;
      position: relative;
    }
    /* For Chrome browsers */
    &::-webkit-slider-thumb {
      @include thumb-style;
    }

    /* For Firefox browsers */
    &::-moz-range-thumb {
      @include thumb-style;
    }
  }
}
