#no-shared-plans
  img
    margin: 70px 0 40px 0
  h4
    font-size: 18px
    margin-bottom: 100px

.plan-shared-status
  span
    color: #B3B3B3
  a
    color: #59C4C4
    text-decoration: none
    cursor: pointer
    i
      margin-right: 10px
    span
      position: relative
      bottom: 2px
      color: #59C4C4
