.task-list::-webkit-scrollbar {
  width: 3px;
}

.task-list::-webkit-scrollbar-track {
  background-color: transparent;
}

.task-list::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 1000000px;
}
