.assigned-plans-card{
  margin-bottom: 30px;

  .assigned-plans-card-filter-row{
    font-size: 12px;
    color: #666;
    display: flex;
    padding-bottom: 10px;

    .actions{
      margin-left: auto;
      display: flex;

      .action-item{
        padding-left: 10px;

        .checkbox-inline{
          user-select: none;
          display: flex;
          align-items: center;

          input[type=checkbox]{
            margin-top: 0px;
          }
        }
      }
      
      a.add-new-link{
        color: #59C4C4;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }

  .assigned-plan-row{
    border: 1px solid #E6E6E6;
    padding: 10px 20px;
    margin-bottom: 10px;
    align-items: center;
    font-size: 12px;
    color: #666666;

    .content{
      width: 80%;
    }

    .action-link{
      color: #59C4C4;
      cursor: pointer;
      
      input[type=checkbox]{
        margin-right: 5px;
      }
    }

    .sub-title{
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;

      .state.completed{
        color: #336E7B;
        padding-right: 5px;
        &:before{
          content: 'Completed';
        }
      }
      .state.started{
        color: #59C4C4;
        &:before{
          content: 'Running';
        }
      }
      .state.paused{
        color: #F2714D;
        &:before{
          content: 'Paused';
        }
      }
      .state.deleted{
        color: #666;
        padding-right: 5px;
        &:before{
          content: 'Deleted';
        }
      }
    }

    .assigned-plan-actions{
      flex-grow: 1;
      text-align: right;

      .assigned-plan-action{
        cursor: pointer;
      }
    }

    .inline-item{
      padding-right: 10px;
      display: inline-block;
    }

    .inline-item:last-child{
      padding-right: 0;
    }
  }
  .assigned-plan-row.deleted{
    background: #F8F7F6;
    color: #808080;
    .inline-item{
      color: #969696!important;
    }
  }
  .assigned-plan-row:last-child{
    margin-bottom: 0;
  }

  .modal-action-button-row{
    padding-top: 30px;
  }

}
