.checkbox {
  margin-bottom: 0.2em;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.checkbox input {
  display: none;
}

.checkbox label {
  padding-left: 30px;
  min-height: 20px;
  cursor: pointer;
}

.checkbox label:before {
  font-family: FontAwesome;
  content: "\f096";
  font-size: 17px;
  position: absolute;
  padding-top: 1px;
  left: 0;
  cursor: pointer;
}

.checkbox input[type=checkbox]:checked + label:before {
  content: "\f14a";
  cursor: pointer;
}

.checkbox label.checked:before {
  content: "\f14a";
  cursor: pointer;
}

p .checkbox label:before {
  margin-top: 12px
}

.inlineEditable .hidden {
  visibility: hidden;
}

.inlineEditable:hover {
  background-color: rgba(0,0,0,0.02);
}

.inlineEditable:hover .hidden {
  visibility: visible;
}

.tagBubble i {
  display: none;
}

.tagBubble:hover i {
  display: inline-block;
}

.faLg {
  font-size: 36px;
}

.blue {
  background-color: rgba(89, 196, 196, 0.2);
}

.gray {
  background-color: rgba(151, 151, 151, 0.2);

}

.blue i {
  color: #59C4C4;
}

.gray i {
  color: #979797;
}

.editAndDelete, .downloadAndDelete, .editAndAdd {
  display: flex;
  justify-content: space-between;
  width: 52px;
}

.editAndDelete .button, .edit .button, .downloadAndDelete .button, .editAndAdd .button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
  text-decoration: none;
}

.edit .smIcon, .editAndDelete .smIcon, .downloadAndDelete .smIcon, .editAndAdd .smIcon {
  font-size: 10px;
}
