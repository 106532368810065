.action-form{
  .add-link{
    font-size: 12px;
    color: #59C4C4;

    i.fa{
      font-size: 12px;
      padding-right: 5px;
    }
  }

  .toggle-close-link{
    font-size: 14px;
    color: #B3B3B3;
  }
}

.carbon-copy-link {
  font-size: 1.1rem;
  i {
    position: relative;
    top: 0.3rem;
  }
}

.cc-selections {
  padding-top: 5px;
  * {
    color: white;
  }
  .cc-selection {
    margin: 2px;
    border-radius: 5px;
    padding: 2px 5px;
    float: left;
    a {
      cursor: pointer;
      padding: 5px;
    }
  }
  .cc-person {
    background: #59C4C4;
  }
  .cc-email {
    background: #666666;
  }
  .cc-role {
    background: #59C4C4;
  }
}

.cc-multi-select-dropdown {
  position: absolute;
  left: 0.8em;
  top: 6.3em;
  max-width: 100px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid 0 0 0 0.15;
  box-shadow: 0 6px 12px;
}

.cc-search-results-container {
  z-index: 3000;
  max-width: 2000px;
  border-radius: 0px;
  border-top: 1px;
}

.cc-search-result {
  line-height: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 7px;
  padding-right: 7px;
  cursor: pointer;

  i {
    margin-right: 10px;
  }
}
