.standard-table{
  .table-header-row{
    padding: 0;
    color: #949393;
    font-size: 12px;

    > div{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .table-empty-row{
    padding-bottom: 10px;
  }

  .table-row{
    padding: 10px 0;

    > div{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .icons .fa{
      color: #666666;
    }
  }
}
