.timelineSkeletonWrapper {
    border-bottom: 1px solid #E6E6E6;
    background-color: #ffffff;
}

.timelineSkeletonWrapperInner {
    height: 100px;
    padding: 15px;
    position: relative;
}

.timelineSkeletonWrapperBody div {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderSkeleton;
    animation-timing-function: linear;
    background: #f6f7f8;
    background-image: -webkit-gradient(linear, left center, right center, from(#f6f7f8), color-stop(.2, #edeef1), color-stop(.4, #f6f7f8), to(#f6f7f8));
    background-image: -webkit-linear-gradient(left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    height: 104px;
    position: absolute;
    right: 15px;
    left: 15px;
    top: 15px;
    bottom: 15px;
}

.timelineSkeletonWrapperBody {
    animation-name: skeletonAnimate;
    background-image: -webkit-gradient(linear, center top, center bottom, from(deg), color-stop(0, red), color-stop(.15, orange), color-stop(.3, yellow), color-stop(.45, green), color-stop(.6, blue), color-stop(.75, indigo), color-stop(.8, violet), to(red));
    background-image: -webkit-linear-gradient(135deg, red 0%, orange 15%, yellow 30%, green 45%, blue 60%,indigo 75%, violet 80%, red 100%);
    background-repeat: repeat;
    background-size: 50% auto;
}

/*.timelineSkeletonWrapperBody div {*/
/*    position: absolute;*/
/*    right: 15px;*/
/*    left: 15px;*/
/*    top: 15px;*/
/*    bottom: 15px;*/
/*}*/

div.timelineSkeletonIcon {
    height: 43px;
    width: 43px;
    margin-left: 5px;
    border-radius: 60px;
    right: auto;
}
div.timelineSkeletonTitle {
    top: 20px;
    height: 15px;
    left: 85px;
    width: 200px;
    border-radius: 20px;
}

div.timelineSkeletonDetail1 {
    left: 85px;
    top: 50px;
    height: 10px;
    width: 300px;
    border-radius: 20px;
}
div.timelineSkeletonDetail2 {
    left: 85px;
    top: 70px;
    height: 10px;
    width: 300px;
    border-radius: 20px;
}

@keyframes placeholderSkeleton {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

@keyframes skeletonAnimate {
    from {
        background-position: top left;
    }
    to {
        background-position: top right;
    }
}
