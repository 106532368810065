.flexxy {
  display: flex;
}
.reportHeader {
  display: flex;
  border-bottom: solid 2px #E6E6E6;
  align-items: baseline;
  overflow-x: auto;
}

.headerTitle {
  width: 150px;
  margin: 5px;
}

.headerTab {
  text-align: center;
  cursor: pointer;
}
.headerTabOptions {
  color: #666;
  padding: .4em .6em;
  margin: 0 4px;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  white-space: nowrap;
}

.headerTabOptions:hover {
  color: #F4B350;
}


.reportHolder {
  display: flex;
  flex-direction: column;
}
.checkBoxLabel {
  font-size: 14px;
  padding-left: 20px;
  color: #666;
}
.checkBoxLabel::before {
  color: #B3B3B3;
}

.singleReportHeader, .singleReportBodyTitle {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background: #fff;
  margin-bottom: 10px;
}
.singleReportBodyTitle {
  border-bottom: solid 1px #E6E6E6;
  margin-bottom: 0;
}

.singleReportTitleSection {
  display: flex;
  flex-direction: column;
}

.singleReportTitle {
  margin: 0;
}

.dateRangeHolder {
  display: flex;
  white-space: nowrap;
  align-items: baseline;
  justify-content: space-between;
}

.selectDate {
  width: 300px;
  margin-left: auto;
}

.reportText {
  font-size: 14px;
}

.activeReport {
  background-color: #e6e6e6;
  font-weight: 600;
}
.backgroundWhite {
  background: #FFF;
}

.upCase {
  text-transform: uppercase;
}
.downCaseH5 {
  text-transform: capitalize;
  font-size: 16px;
}
.noWrap {
  white-space: nowrap;
}
.noBorder {
  border: none !important;
}
.noMarginRight {
  margin-right: 0;
}

.noWrap img {
  height: 40px;
  width: 40px;
}
.loadingState {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 999;
  text-align: center;
  opacity: 0.5;
  padding-top: 80px;
}

.loadingState .fa-spinner {
  color: black;
}
.positionedSpinner {
  font-size: 50px;
  margin-left: -450px;
}
.columnGenerator {
  display: inline-block;
  background: #FFF;
}
.card_gray_text {
  color: #999999;
}
.card_arrangement {
  justify-content: "space-evenly";
  padding-top: "17px";
  background-color: "#ffffff";
}

.total_sign_width {
  width: 15.2%;
}
@media (max-width: 1355px) {
  .total_sign_width {
    width: 15.3%;
  }
}

.no_show_width {
  width: 11.9%;
}

@media (max-width: 1120px) {
  .no_show_width {
    width: 12.1%;
  }
}

.buyer_signed_width {
  width: 13.7%;
}

@media (max-width: 1364px) {
  .buyer_signed_width {
    width: 14%;
  }
}

.seller_signed_width {
  width: 13.7%;
}
.total_seller_signed_width {
  width: 13.67%;
}
@media (max-width: 1456px) {
  .seller_signed_width , .total_seller_signed_width {
    width: 14%;
  }
}
.arrow_container {
  display:flex;
  justify-content: center;
  align-items: center;
}

 #hiding_last_arrow:last-of-type {
 display: none;
}

.heightDropdown {
  height: 16rem !important;
}

.selecting_first_element:first-of-type {
  margin-top: -14px
}

.border_right {
 border-right: 1px solid #ddd
}

.cascading_first_letter:first-letter  {
  text-transform: uppercase;
}

.padding_for_list_items {
  margin-left: 42%
}
