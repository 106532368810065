#lead-dna-details {
  .form-group {
    min-height: unset;
    margin: 12px 0 0;
  }

  .leaddna-filter-dropdown {
    display: inline-block;
    width: 100px;
  }

  .inline-label {
    display: inline-block !important;
    position: relative;
    bottom: 11px;
    margin-right: 7px;
    margin-left: 10px;
  }

  .chart-container {
    margin-left: -10px;
    position: relative;
    height: 60px;
  }

  #secondary-select-wrapper {
    display: inline-block;
    background-color: lightgrey;
    height: 30px;
    border: 1px solid #ccc;
    position: relative;

    #secondary-close-box {
      display: inline-block;
      text-align: center;
      width: 27px;
      height: 30px;
      position: relative;
      bottom: 15px;

      i.fa.fa-times-circle {
        position: relative;
        top: 5px;
      }
    }

    .secondary-filter-dropdown {
      position: relative;
      bottom: 1px;
      left: 1px;
    }
  }

  .leaddna-legend {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;

    li {
      padding-left: 1em;
      text-indent: -.7em;
      display: inline;
      position: relative;
      margin-right: 15px;
      color: #b3b3b3;
    }

    li::before {
      content: "•  ";
      font-size: 39px;
      position: absolute;
      top: -18px;
      left: 8px;
    }

    span.color-text {
      font-weight: 700;
    }

    li.light-blue {
      span.light-blue, &::before {
        color: rgb(89,196,196);
      }
    }

    li.yellow {
      span.yellow, &::before {
        color: rgb(244,179,80);
      }
    }

    li.dark-red {
      span.dark-red, &::before {
        color: rgb(188,52,14);
      }
    }

    li.dark-blue {
      span.dark-blue, &::before {
        color: rgb(51,110,123);
      }
    }

    li.coral-red {
      span.coral-red, &::before {
        color: rgb(242,113,77);
      }
    }

    li.gray {
      span.gray, &::before {
        color: rgb(179,179,179);
      }
    }
  }
}

#web-activity {
  a {
    text-decoration: none;
    cursor: pointer;
  }

  a:hover {
    color: #59C4C4;
  }

  .visits {
    display: inline-block;
    width: 29px;
  }

  .favorites {
    display: inline-block;
    width: 51px;
  }

  .price {
    display: inline-block;
    width: 52px;
  }

  .links {
    font-size: 16px;
    display: inline-flex;
    justify-content: space-between;
  }
}

#activity-metrics {
  display: flex;
  width: 400px;
  justify-content: space-between;
  margin-right: -100px;
  color: #666;

  .activity-label {
    font-size: 10px;
    font-weight: 600;
  }

  .activity-value {
    font-size: 12px;
    font-weight: 600;
    margin-left: 4px;
  }
}
