.cardRow {
  padding-bottom: 22px;
}

.cardRowNarrow {
  padding-bottom: 8px;
}

.cardRow:last-of-type, .cardRowNarrow:last-of-type {
  padding-bottom: 0;
}

.viewAll {
  cursor: pointer;
  font-size: 10px;
  font-weight: bold;
  line-height: 14px;
  text-decoration: none;
}

.viewAll:hover {
  text-decoration: none;
  color: #59C4C4;
}

.rowHoverBg {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.fullRowHover {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 10px;
  padding-right: 4px;
}
