$brand-primary: #3B3B3B
$gray: #B3B3B3

#account-financials-index
  h2
    font-size: 24px
    color: $brand-primary
    margin: 20px 0
    font-weight: normal
.financial-header
  position: relative
  margin: 15px 0 20px 0px
  h2
    font-size: 24px
    color: $brand-primary
    font-weight: 400
  p
    color: $gray
    font-weight: 400
    margin: 0
    text-align: justify
    position: relative
  button
    margin-left: 15px
.transaction-gci-wrap, .collaborator-expense-wrap
  border: 1px solid $gray
.transaction-gci-options, .collaborator-options
  background-color: #FBFAFA
  border-top: 1px solid $gray
  padding: 15px
  .transaction-gci-header.dnd, .collaborator-header.dnd
    margin-bottom: 10px
  .transaction-gci-header, .collaborator-header
    padding-bottom: 15px
    h3
      margin: 0
      display: inline-block
      font-size: 17px
      color: #3B3B3B
      font-weight: 400
    button
      font-size: 14px
      text-transform: uppercase
      i
        color: #63cbcb
        font-size: 15px
        line-height: 20px
        padding-right: 10px
  &:first-child
    .transaction-gci-content, .collaborator-content
      border-top: 0
  .transaction-gci-content, .collaborator-content
    position: relative
  .modal-delete-expense
    position: absolute
    margin-top: 5px
  .drag-handler
    margin-top: 2.5em
    width: 2.25em
    img
      width: 1em
  .transaction-gci-content.content-additional, .collaborator-content.content-additional
    margin-left: 3em
    margin-right: 1.5em
    .row
      border-top: 1px solid $gray
      padding-top: 10px
  input
    margin-bottom: 10px
  .transaction-input
    position: relative
    span
      position: absolute
      top: 0
      line-height: 30px
      color: $gray !important
      font-size: 14px !important
      font-weight: 400
    input
      text-align: right
      background-color: #fff
  .percentage-input
    span
      right: 7px
    input
      padding-right: 25px
  .flat-input
    span
      left: 7px
    input
      padding-left: 25px
  .date-input
    .date
      background-color: #fff
    .date ~ .fa-calendar
      bottom: 33.5px
  .transaction-input-flip
    padding-left: 0
    &:last-child
      padding-left: 20px
    label:before
      visibility: visible
      font-family: "FontAwesome" !important
      font-size: initial
      content: '\f10c' !important
      position: relative
      padding-right: 5px
      color: #b3b3b3
      cursor: pointer
      vertical-align: middle
    &.selected
      label:before
        content: '\f192' !important
        color: #59C4C5
    input[type=radio]
      visibility: hidden !important
      margin: 0 !important
      width: 0 !important
  label.transaction-input-flip
    &:before
      visibility: visible
      font-family: "FontAwesome" !important
      font-size: initial
      content: '\f10c' !important
      position: relative
      padding-right: 5px
      color: #b3b3b3
      cursor: pointer
      vertical-align: middle
    &.selected:before
      content: '\f192' !important
      color: #59C4C5
.transaction-modal
  background-color: unset
  border-bottom: unset
  padding: 0
  .modal-description
    color: $gray
.agent-expenses-wrap
  padding-bottom: 20px
[id^="accordion"] .title a
  img, .default-avatar
    width: 26px
    height: 26px
    margin-right: 7px
    border-radius: 50%
  .default-avatar
    width: 26px !important
    line-height: 26px
    font-size: 14px
  &:after
    content: "\f0d8"
    font-size: 15px
    font-family: FontAwesome
    position: absolute
    transition: all 0.3s ease-in-out 0s
    right: -16px
    top: 4px
    transform: rotate(0deg)
    color: #717171
  &[data-js-collapsed="true"]
    &:after
      transform: rotate(180deg)
.settings-view-wrap
  .form-group
    span
      font-size: 18px
.financial-start-wrap
  padding: 0 0 20px 10px
  p
    margin: 15px 0 20px 0 !important
  .transaction-gci-options, .collaborator-options
    background-color: #fff
    border-bottom: 0
    padding: 0
    .transaction-gci-content, .collaborator-content
      padding: 0
      border: 0
.delete-expense
  right: 10px
  position: absolute
  top: 40%
.delete-expense:hover
  cursor: pointer
.financial-details-wrap
  padding-bottom: 100px
  .transaction-gci-wrap, .collaborator-expense-wrap
    border: 1px solid #e2e2e2
    .transaction-gci-options, .collaborator-options
      padding: 0
      background-color: #fff
      .transaction-gci-header, .collaborator-header
        padding: 5px
        background-color: #f1f3f7
        h3
          font-size: 13px
          text-transform: uppercase
          font-weight: 700
          color: #5b5b5b
        .add
          a
            color: #59C4C4
      .transaction-gci-content, .collaborator-content
        border-top: 1px solid #e2e2e2
        padding: 15px
        label
          display: block
.setting-financial-view
  .transaction-gci-content, .collaborator-content
    span
      font-size: 14px
      color: #666666

.transaction-gci-footer, .collaborator-expense-footer
  background-color: #176f7d
  color: #fff
  padding: 15px
  .definition
    h4
      margin: 0 0 0 30px
      font-size: 16px
      font-weight: 700
  .total-amount
    h4
      margin: 0
      font-size: 16px
      font-weight: 700
.edit-agent-wrap, .edit-collaborator-wrap
  margin-bottom: 20px
  h2
    font-size: 14px
    font-weight: 400
    padding: 10px 0
  span
    font-weight: 400

.transaction-gci-options, .collaborator-options
  .form-group
    margin-left: 0
    margin-right: 0
    label
      margin-bottom: 5px !important
    .radio-inline
      padding-top: 0
      padding-right: 0
.modal-header
  padding: 15px
  .transaction-gci-options, .collaborator-options
    background-color: transparent
    padding: 0
    border: 0
    input[type="text"]
      height: 30px
  .financial-header
    margin: 15px 0 20px 0px
  .add-agent-badge
    position: relative
    .bootstrap-select
      width: 96%
.add_agent_link
  color: #59C4C4
  display: block
#content
  .add-expense-modal
    .form-group
      min-height: inherit
      margin-bottom: 7px
.remove-income
  position: relative
  top: 1px
  float: right
  margin-right: 15px
  color: #949393
  &:hover
    cursor: pointer
.agent-split-drag-handler
  .drag-handler
    margin: 0
    left: 15px
    top: -10px
    
