#date
  margin-top: 0px !important
.label
  font-size: 10px !important
  font-family: 'Open Sans', sans-serif
  color: #666666
  padding: 0
  text-transform: uppercase
.bubble-label
  font-size: 10px
.select-format
  border: none
  background-color: #ECEFEF
.input-format
  border: none !important
  width: 100%
  height: 30px
.bubble-frame
  background-color: #59C4C4
  color: #fff
  border-radius: 30px
  padding: 2px 11px
.task-header
   font-size: 2em
   padding: 30px 0 0 15px
   color: #59C4C4
   font-weight: lighter
#new-task-modal
  .modal-dialog
    width: 750px
#log-appointment
  .modal-dialog
    width: 750px
#add-task-modal
  .modal-dialog
    width: 750px
#add-appointment-modal
  .modal-dialog
    width: 750px
#edit-appointment-modal
  .modal-dialog
    width:750px
.close-button
  font-size: 2em
  margin-top: 10px
  margin-left: -93px
  color: #ededed
.x-button
  margin-top: 16px
  margin-left: -22.5px
  color: #bcbcbc
.cancel-button
   border-radius: 30px
   background-color: #f0f1f1
   color: #9F9F9F
   padding: 5px 20px
.save-button
   padding: 5px 40px
.checkbox-complete
   margin-left: 3em
   margin-top: -8px
.task-for
   height: 30px
   margin-top: 4px !important
.font-12
  font-size: 12px
.checkbox.complete-checkbox
  input[type=checkbox]
    display: none
  label.unchecked:before
    font-family: FontAwesome
    content: "\f096"
    font-size: 22px
  input[type=checkbox]:checked + label:before
    font-family: FontAwesome
    content: "\f046"
    font-size: 22px
    color: #336E7B
  label.checked:before
    font-family: FontAwesome
    content: "\f046"
    font-size: 22px
    color: #59C4C4
.pointer-maker:hover
  cursor: pointer
.checkbox-log
  display: inline-block
  font-size: 1.6em
  margin-top: 12px
  padding-left: 2em
.teal-box
  color: #59C4C4
.add-appointment-adjuster
  margin: 14px 0 0 2em
.add-appointment-complete
  font-size: 14px
  padding-top: 2px
.icon-adjuster
  color: #fff
  background-color: #59C4C4
  padding: 4.5px 9px 8.5px 9px
  margin: 0 3px
  border-radius: 3px
.task-form-checkbox
  margin: 4px 0 0 2em
  font-size: 1.6em
.mark-as-complete
  font-size: 14px
  margin-top: 7px
  padding-left: 10px
  font-family: "Open Sans"
.task-form-footer
  display: flex
  align-items: center
.complete-label
  font-size: 1.6em 
.new-modal-header
  display: flex
  justify-content: space-between
  align-items: center
#edit-task-modal
  .modal-dialog
    width: 810px
  .modal-content
    padding: 0 18px
.date-log-form
  margin-top: -69px
  margin-left: 330px
.time-log-form
  margin-top: -69px
  margin-left: 489px
