.brivityBlue {
  color: #59c4c4;
}

.brivityOrange {
  color: #f2714d;
}

.yellowOrange {
  color: #f4b350;
}

.darkBlue {
  color: #336e7b;
}

.gray {
  color: rgb(151, 151, 151);
}

.grayBase {
  color: #666666;
}

.statusNew {
  background-color: #ae4d2a !important;
  color: #ffffff !important;
}

.statusUnqual {
  background-color: #76c2c2 !important;
  color: #ffffff !important;
}

.statusNurt {
  background-color: #f2c24f !important;
  color: #ffffff !important;
}

.statusWatch {
  background-color: #426d79 !important;
  color: #ffffff !important;
}

.statusHot {
  background-color: #e27857 !important;
  color: #ffffff !important;
}

.statusPending {
  background-color: #e9b761 !important;
  color: #ffffff !important;
}

.statusActiveClient {
  box-shadow: 0 0 0 1.5px #FAC5C3;
  color: #ae4d2a !important;
}

.statusOther {
  box-shadow: 0 0 0 1.5px #666666;
  color: #666666 !important;
}

.statusProspectiveClient {
  box-shadow: 0 0 0 1.5px #fae4c3;
  color: #ad6714 !important;
}

.statusPast {
  background-color: #b4b3b3 !important;
  color: #ffffff !important;
}

.statusInactive {
  background-color: #999999 !important;
  color: #ffffff !important;
}

.statusArchived {
  background-color: #999999 !important;
  color: #ffffff !important;
}

.statusTrash {
  background-color: #666666 !important;
  color: #ffffff !important;
}

.intentBuyer {
  color: #2e6ce5;
  border-color: #c8d9fa !important;
}

.intentBuyer:hover {
  border-color: #96b7fa !important;
}

.intentBuyer:active {
  border-color: #578efa !important;
}

.intentSeller {
  color: #e5941b;
  border-color: #fae4c3 !important;
}

.intentSeller:hover {
  border-color: #fad296 !important;
}

.intentSeller:active {
  border-color: #fab957 !important;
}

.intentSellerBuyer {
  color: #6c36d9;
  border-color: #d8c8fa !important;
}

.intentSellerBuyer:hover {
  border-color: #b796fa !important;
}

.intentSellerBuyer:active {
  border-color: #8e57fa !important;
}
.intentTenant {
  color: #36b25f;
  border-color: #caf0d6 !important;
}

.intentTenant:hover {
  border-color: #9de0b4 !important;
}

.intentTenant:active {
  border-color: #63d689 !important;
}

.intentLandlord {
  color: #E94C46;
  border-color: #f1c8c5 !important;
}

.intentLandlord:hover {
  border-color: #f4a4a1 !important;
}

.intentLandlord:active {
  border-color: #E94C46 !important;
}

.intentRecruit {
  color: #e56322;
  border-color: #fad5c3 !important;
}

.intentRecruit:hover {
  border-color: #fab796 !important;
}

.intentRecruit:active {
  border-color: #fa8e58 !important;
}

.intentCandidate {
  color: #E5378E;
  border-color: #FAC8E1 !important;
}

.intentCandidate:hover {
  border-color: #FA96C8 !important;
}

.intentCandidate:active {
  border-color: #FA64AF !important;
}

.intentNone {
  color: #666666;
  border-color: #ebebeb !important;
}

.intentNone:hover {
  border-color: #c2c2c2 !important;
}

.intentNone:active {
  border-color: #999999 !important;
}
