.actionButton {
  margin-left: 20px;
  cursor: pointer;
}

.completed {
  color: #336E7B;
}

.deleted {
  color: #666;
}

.started {
  color: #59C4C4;
}

.paused {
  color: #F2714D
}

.actionButtons {
  margin-left: auto;
  margin-right: 20px;
}

.headerName {
  font-size: 18px !important;
  padding-left: 15px;
}

.state {
  font-weight: bold;
}

.closeAutoPlan {
  top: 1px !important;
  margin-right: 15px !important;
}

.planName {
  font-weight: 600;
  color: #666;
  margin: 0;
  width: 75%;
}

.planName span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.cardState {
  font-weight: bold;
  font-size: 10px;
}
