.btn {
  border: none;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none !important;
}

.btnLg {
  font-size: 15px;
  padding: 7px 18px;
  border-radius: 18px;
  font-weight: 600;
  height: 36px;
  line-height: 20px;
}

.btnLgSquare {
  font-size: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 2px;
  font-weight: 600;
  width: 100%
}

.btnSm {
  font-size: 10px;
  padding: 4px 14px;
  border-radius: 18px;
  height: auto;
  width: auto;
  line-height: 14px;
  display: inline-block;
}

.btnSm i {
  font-size: 12px;
  margin-right: 6px;
}

.btnSmNarrow {
  composes: btnSm;
  padding: 4px 13px;
  line-height: 14px;
}

.btnBlueDisabled {
  color: #FFFFFF;
  background-color: #59c4c4;
  opacity: 0.5;
  cursor: not-allowed;
}

.btnBlueDisabled:hover {
  color: #FFFFFF;
}

.btnBlue {
  color: #FFFFFF;
  background-color: #59c4c4;
}

.btnBlue:hover {
  color:rgba(89, 196, 196, 1);
  background-color: rgba(89, 196, 196, 0.3);
}

button.btnBlue:focus {
  color:rgba(89, 196, 196, 1);
  background-color: rgba(89, 196, 196, 0.3);
  outline: 0;
}

button.btnBlue:disabled {
  color: #FFFFFF !important;
  background-color: #59c4c4 !important;
  opacity: 0.5;
}

.btnGray {
  color: rgba(151, 151, 151, 1);
  background-color: rgba(151, 151, 151, 0.2);
}

.btnGray:hover {
  color: rgba(151, 151, 151,1);
  background-color: rgba(151, 151, 151,0.3);
}

button.btnGray:focus {
  color: rgba(151, 151, 151,1);
  background-color: rgba(151, 151, 151,0.3);
  outline: 0;
}

.btnDarkBlue {
  color: #FFFFFF;
  background-color: #336E7B;
}

.btnDarkBlue:hover {
  color: #FFFFFF;
  background-color: #336E7B;
}

.btnDanger {
  color: #FA3D37;
  background-color: #FFFFFF;
  outline: 2px solid #FA3D37;
  outline-offset: -2px;
}

.btnDanger:hover {
  color: #FFFFFF;
  background-color: #FA3D37;
}

.btnYellow {
  color: #FFFFFF;
  background-color: #F4B350;
}

.btnYellow:hover {
  color: #FFFFFF;
  background-color: #F4B350;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.btnRed {
  background-color: rgba(188, 69, 29, 0.2);
  color: #BC451D;
}

.btnDropdown button {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
}

.btnDropdown button:hover, .btnDropdown .open button, .btnDropdown .open button:hover, .btnDropdown .open > .btn-default.dropdown-toggle {
  color: inherit !important;
  background-color: inherit !important;
  box-shadow: none !important;
}

.btnDropdown.btnSm {
  padding-left: 8px;
  padding-right: 8px;
  min-width: 60px;
  cursor: pointer;
}

.btnDropdown.btnSm button {
  font-size: 10px;
  font-weight: bold;
}

.btnDropdown > button:after {
  font-family: FontAwesome;
  content: "\F078";
  font-size: 8px;
  margin-left: 6px;
  position: relative;
  bottom: 1px;
}

.btnDropdown .btn-group-test button {
  left: -11px !important;
}

.btn:disabled {
  color: rgba(151, 151, 151, 1);
  background-color: rgba(151, 151, 151, 0.2);
}

.inactive {
  opacity: 0.5;
  cursor: default;
  color: #FFFFFF !important;
  background-color: #59c4c4 !important;
}

.btnSecondary {
  border: 2px solid #59c4c4;
  color: #59c4c4;
  background-color: #fff;
}

.btnSecondary:hover, button.btnSecondary:focus {
  color:#fff;
  background-color: #59c4c4;
  outline: 0;
}
