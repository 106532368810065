.thumb img {
  width: 95px;
  height: 63px;
}

.thumbLg img {
  width: 170px;
  height: 80px;
}

.transactionDetails {
  font-size: 12px;
  line-height: 17px;
}

.transactionDetails p {
  margin-bottom: 2px;
}

.handShake {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  text-align: center;
  line-height: 190%;
  border: 2px solid #666666;
  font-size: 20px;
}

.modalRow {
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 30px;
  margin-bottom: 40px;
}

.modalRow p {
  font-size: 14px;
}

.modalRow:first-of-type {
  border-top: 1px solid #E6E6E6;
  padding-top: 40px;
  margin-top: 10px;
}

.overflow {
  padding-left: 0px;
  display: inline-block;
  width: 70%;
  position: relative;
}

.modalScroll {
  max-height: 80vh;
  overflow-y: auto;
}

.searchResults {
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #CCC;
  left: 0;
  right: 0;
  margin: 0 10px;
  box-shadow: 0 1px 5px 1px rgba(0,0,0,0.15), 0 10px 16px 0 rgba(0,0,0,0.2);
  max-height: 25em;
  overflow: auto;
  position: absolute;
}

.resultChildren .resultItem {
  cursor: pointer;
  line-height: 20px;
  padding: 7px 7px 7px 12px;
}
.categories:first-child {
  padding: 10px 0 0 10px;
} 
.categories {
  padding: 0px 10px 0 10px;
}

.result{
  padding: 10px 0 10px 20px !important
}

.resultChildren .resultItem.highlight {
  background: #F8F7F6;
  color: #333;
}

.addRolePlus {
  margin-left: 4px;
  margin-right: 0 !important;
}

.previewCard {
  padding: 16px;
  box-shadow: 0 2px 8px rgba(213, 213, 213, 0.5);
  border-radius: 8px;
}

.previewCard > .header {
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 12px;
}

.previewCard > .content {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.previewCard > .content .listingImg > img {
  height: auto;
  width: 150px;
}

.previewCard > .content .details p:first-child {
  font-weight: 600;
}

.previewCard > .content .details {
  margin-left: 16px;
}

.previewCard p {
  margin: 0;
}

.previewCard .mlsCredit {
  color: #b8b8b8;
  font-size: 12px;
  line-height: 16px;
}

.deleteIcon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f3f3f3;
}

.deleteIcon:hover {
  background-color: #b8b8b8;
}

.deleteIcon i {
  position: absolute;
  top: 7px;
  right: 8.75px;
  font-size: 15px;
  line-height: 15px;
  color: #979797;
}

@media screen and (min-width: 1764px) and (max-width: 1974px) {
  .overflow {
    width: 65%;
  }
}

@media screen and (min-width: 1595px) and (max-width: 1763px) {
  .overflow {
    width: 60%;
  }
}

@media screen and (min-width: 1463px) and (max-width: 1594px) {
  .overflow {
    width: 55%;
  }
}

@media screen and (min-width: 1303px) and (max-width: 1462px) {
  .overflow {
    width: 50%;
  }
}

@media screen and (max-width: 1302px) {
  .overflow {
    width: 45%;
  }
}
