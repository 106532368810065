.table-sticky {
  th {
    position: sticky;
    top: 0;
  }

  th:after,
  th:before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
  }

  th:before {
    top: 0;
    border-top: 1px solid #ebebeb;
  }

  th:after {
    bottom: 0px;
    border-bottom: 1px solid #ebebeb;
  }
}
