.modalTitle {
  color: #59C4C4;
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
}

.newModalTitle {
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
}
.sectionHeader {
  font-size: 14px;
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 9px;
  color: #666666;
  line-height: 19px;
  margin-bottom: 10px;
}

.pT32 {
  padding-top: 32px;
}

.pT30 {
  padding-top: 30px;
}

.pT20 {
  padding-top: 20px;
}

.pT10 {
  padding-top: 10px
}

.pT8 {
  padding-top: 8px;
}

.pB15 {
  padding-bottom: 15px;
}

.pB16 {
  padding-bottom: 16px;
}

.pB24 {
  padding-bottom: 24px;
}

.pB30 {
  padding-bottom: 30px;
}

.mT20 {
  margin-top: 20px !important;
}

.mB0 {
  margin-bottom: 0;
}

.mB24 {
  margin-bottom: 24px;
}

.mR16 {
  margin-right: 16px;
}

.uploadPersonalPhoto {
  display: inline-block;
  width: 55px;
  height: 55px;
  font-size: 6px;
  font-weight: 600;
  background-color: rgba(151, 151, 151, 0.2);;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}

.uploadPersonalPhoto i {
  font-size: 12px;
  margin-top: 14px;
  margin-bottom: 2px;
}

.uploadPersonalPhoto span {
  display: inline-block;
}

.uploadPersonalPhoto .initials {
  font-size: 17px;
  padding-top: 15px;
}

.uploadPersonalPhoto .uploadAvatarIcon i {
  font-size: 17px;
  padding-top: 5px;
}

.uploadPersonalPhoto img {
  border-radius: 50%;
}

.uploadAvatarText {
  color: #666666;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  padding-top: 20px;
  padding-left: 15px;
  cursor: pointer;
}

.divider {
  border-top-color: #E6E6E6;
  margin: 0
}

.modalBodyContainer {
  height: 475px;
  overflow-x: scroll;
}

.primaryDetailColor {
  color: #59c4c4
}

.makePrimaryLink {
  cursor: pointer;
}

.centerItems {
  display: flex;
  align-items: center;
  flex-direction: column;
}
