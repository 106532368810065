.listing-details-modal, .agent-details-modal {
  .modal-content {
    border-radius: unset;
  }
  .modal-title {
    border-bottom: none;
    padding-bottom: 0px;
  }
  .modal-header {
    padding-bottom: 12px;
    padding-right: 46px;
    padding-left: 46px;
    padding-top: 32px !important;
  }
  .modal-body {
    padding-right: 30px; // To account for the scrollbar
    padding-left: 46px;
    padding-top: 0;
    overflow-y: auto;
    height: calc(100vh - 76px);
  }
}

.agent-details-modal {
  .modal-header {
    padding-bottom: 8px;
  }
}
