.tw-checkbox {
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: white;
  width: 16px;
  height: 16px;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

.tw-checkbox::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em white;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

.tw-checkbox:checked::before {
  transform: scale(1);
}

.tw-checkbox:focus {
  /* !important is to override bootstrap */
  outline: 0 !important;
}
