.trigger-form{
  .divider{
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.triggers-wrap{
  .trigger-table-empty-row{
    padding-bottom: 10px;
  }

  .toggle-on, .toggle-off{
    cursor: pointer;
  }
}

.trigger-modal-body{
  padding-bottom: 0px;
}
