@media screen and (max-width: 960px) {
  .formClass {
    display: flex;
    flex-grow: 1;
    width: 37% !important;
  }
}

.formClass {
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 10px 10px;
  border: 0;
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: none;
  width: 50%;
  float: left;
}

.formGroup {
  width: 100%;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}

input.formControl {
  display: inline-block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #666;
  background-color: #fff;
  background-image: none;
  border: 1px solid var(--navbar-search-border-color);
  border-radius: 4px;
}

input.formControl:focus {
  border-color: var(--navbar-search-border-color-active);
  outline-color: var(--navbar-search-border-color-active);
}

.searchInputContainer:focus-within > .searchIcon {
  color: red;
  border: 30px solid purple;
}

.searchDropdown {
  box-shadow: none;
  max-width: 974px;
  left: 0;
  background: transparent;
  position: relative;
  border: 0;
  border-radius: 4px;
  .searchInputContainer {
    background: transparent;
    padding: 0px;
    border: 0;
  }
}

.resultsContainer {
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.15), 0 10px 16px 0 rgba(0, 0, 0, 0.2);
  max-height: 25em;
  overflow: auto;
  position: absolute;
}

.searchResultItem,
.searchResultItemActive {
  cursor: pointer;
  padding: 7px;
  white-space: nowrap;
  font-size: 14px;
  color: #666;
  border-radius: 0;
}

div.searchResultItemActive {
  font-weight: bold;
  background: #e6e6e6;
  color: #666;
}

.searchResultItem a,
.searchResultItemActive a {
  color: #666 !important;
}

.searchResultItem a:hover,
.searchResultItemActive a {
  text-decoration: none !important;
}

.searchResultItem i,
.searchResultItemActive i {
  font-size: 17px;
  padding: 0 10px 0 0;
  white-space: nowrap;
  background-color: transparent;
  color: #666;
}

.searchWarning {
  background: #fff;
  position: relative;
  border-radius: 2px;
  cursor: text;
  max-height: 10em;
  min-height: -webkit-calc(2em + 4px);
  min-height: calc(2em + 4px);
  overflow: auto;
  padding: 5px;
  padding: 7px;
}
