.flexxy {
  display: flex;
}
.dashboardHeader {
  display: flex;
  border-bottom: solid 2px #e6e6e6;
  margin-left: 5px;
  align-items: baseline;
}

.headerTab {
  text-align: center;
  cursor: pointer;
}

.headerTabOptions {
  color: #666;
  padding: 0.4em 0.6em;
  margin: 0 4px;
  font-size: 20px;
  cursor: pointer;
}

.dashboardHolder {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  background: #fff;
  margin-top: 19px;
  min-height: 800px;
}

.dashboardSectionTab {
  display: flex;
  justify-content: space-between;
  padding: 4px;
  padding-top: 8px;
  padding-left: 8px;
  border-bottom: solid 2px #e6e6e6;
  margin-bottom: 10px;
}
.dashboardTabTitleSection {
  display: flex;
  flex-direction: column;
}

.activeTabBorder {
  border-bottom: 4px solid #59c4c4;
  margin-bottom: -8px;
}
.filterTitle {
  display: flex;
  background-color: #f8f7f6;
  margin: 2px;
  padding: 8px 0px 2px 7px;
  font-weight: 300;
  border-bottom: 2px solid #e6e6e6;
}

.refreshIconHolder {
  margin-left: 78px;
}

.refreshIcon {
  font-size: 14px;
  color: #b2b2b4;
  cursor: pointer;
}

.filterItem {
  padding: 2px 5px 6px 5px;
}

.filterItemDismiss {
  padding: 2px 5px 3px 5px;
}
.filterItemDismiss .checkBoxLabel {
  padding-top: 6px;
}

.selectUsersAndFilterHolder {
  display: flex;
  align-items: baseline;
}

.selectUsers {
  width: 200px;
  margin-right: 20px;
}

.checkBoxLabel {
  font-size: 12px;
  padding-left: 20px;
  color: #666;
}
.checkBoxLabel::before {
  color: #59c4c4;
  padding-left: 8px;
  padding-top: 0 !important;
}

.eventItemsContainer {
  display: flex;
  flex-direction: column;
}

.loadingState {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 999;
  text-align: center;
  opacity: 0.5;
  padding-top: 80px;
}

.loadingState .fa-spinner {
  color: black;
}

.eventItem {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #e6e6e6;
  min-height: 100px;
  opacity: 1;
}

.eventItemFadeOut {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #e6e6e6;
  transition:
    height 1000ms 1000ms,
    opacity 1000ms 0ms;
  opacity: 0;
  height: 0px;
}

.innerPhotoInfo {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 80%;
  color: #fff;
  text-shadow: #000 0 0 1px;
}

.innerPhotoInfo > span {
  display: block;
  font-size: 14px;
}
.innerPhotoInfo .innerPhotoPrice {
  font-size: 24px;
}

.iconStyle {
  font-size: 22px !important;
  color: #666666;
  background-color: #fff;
  padding: 0;
  border: 2px solid #666;
  border-radius: 50%;
}

.unsubscribedHolder {
  border-radius: 50%;
  border: 2px solid #666;
  margin-bottom: 25px;
  margin-top: -8px;
  margin-right: 15px;
}

.unsubscribedImage {
  padding: 11px 7px 0px 7px;
}

.dashboardTabTitle {
  margin: 0;
}

.noBorder {
  border: none !important;
}

.boldText {
  font-weight: 600;
  color: #666;
}

.redText {
  color: #f2714d;
}
.leadQuickStats {
  display: flex;
  background: #fff;
  overflow: scroll;
}
.leadQuickStatCard {
  width: 100%;
  min-width: 16rem;
  height: 137px;
  display: inline-block;
  border-radius: 10px;
  padding: 0px 20px 20px;
  margin: 5px 10px 26px;
  box-shadow: 0 2px 6px 0 rgba(200, 200, 200, 0.5);
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.statCardWidth {
  min-width: 14rem !important;
  width: 0px;
}
.dashCardConstrained {
  margin: auto;
  max-width: 124px;
}
.leadQuickStatCard:hover {
  background-color: #f8fdfd;
  cursor: pointer;
}
.leadQuickStatCardZero:hover {
  background-color: rgba(0, 0, 0, 0.01);
  cursor: pointer;
}
.opportunityContainer {
  margin-top: 20px;
  background: #fff;
}
.opportunityContainerHeader {
  padding: 20px 0px 20px 20px;
  margin-right: 0px;
  display: block;
}
.opportunityContainerHeader h3 {
  display: inline;
  font-size: 20px;
}
