.leaderboards {
    &:fullscreen {
        overflow: scroll !important;
    }
    &:-ms-fullscreen {
        overflow: scroll !important;
    }
    &:-webkit-full-screen {
        overflow: scroll !important;
    }
    &:-moz-full-screen {
        overflow: scroll !important;
    }

    .diamond-base {
        width: 0;
        height: 0;
        border: 15px solid transparent;
        position: relative;
        top:  -34px;
        left: 14px;
    }

    .diamond-base-after {
        content: '';
        position: absolute;
        left: -15px;
        top: 19px;
        width: 0;
        height: 0;
        border: 15px solid transparent;
    }

    .diamond {
        @extend .diamond-base;
        border-bottom: 19px solid #3270fa;

        &::after {
            @extend .diamond-base-after;
            border-top: 19px solid #3270fa;
        }
    }

    .diamond-brivity {
        @extend .diamond;
        border-bottom: 19px solid #59c4c4;

        &::after {
            @extend .diamond-base-after;
            border-top: 19px solid #59c4c4;
        }
    }

    .not-selected-header {
        border-bottom: 1px solid #EBEBEB;
    }

    .selected-header {
        border-width: 2px 2px 1px 2px;
        border-color: #59c4c4 #59c4c4 #ebebeb #59c4c4;
        border-style: solid;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .selected-cells {
        border-width: 2px;
        border-color: #59c4c4;
        border-style: none solid none solid;
    }

    .selected-bottom {
        border-width: 2px;
        border-color: #59c4c4;
        border-style: none solid solid;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .place-selected-header {
        @extend .selected-header;
        border-color: #3270fa #3270fa #ebebeb #3270fa;
    }

    .place-selected-cells {
        @extend .selected-cells;
        border-color: #3270fa;
    }

    .place-selected-bottom {
        @extend .selected-bottom;
        border-color: #3270fa;
    }
}
